<template>
  <div>
    <topHeader :userData="userData" />
    <section>
      <div class="container">
        <div class="profiledetail">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5"></div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-7">
              <div class="__movleft">
                <p class="profiledetail__name">Edit profile</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5">
              <div class="__fixedimg">
                <div class="avatar-upload">
                  <div class="avatar-preview">
                    <template v-if="userData.company">
                      <div
                        id="imagePreview"
                        :style="{
                          'background-image': userData.company.logo ? 'url(' + userData.company.logo + ')': 'url('+require('../assets/img/default-img.jpg')+')'
                        }"
                      ></div>
                    </template>
                  </div>
                </div>
                <template v-if="userData.company.facebook_url|| userData.company.twitter_url || userData.company.instagram_url || userData.company.linkedin_url">
                  <hr />
                  <div class="row">
                    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
                      <a :href="`${userData.company.twitter_url}`" target="_blank" v-if="userData.company.twitter_url">
                        <img src="../assets/img/twitter.png" class="__sociaimgs"/>
                      </a>
                      <a :href="`${userData.company.instagram_url}`" target="_blank" v-if="userData.company.instagram_url">
                        <img src="../assets/img/instagram.png" class="__sociaimgs" />
                      </a>
                      <a :href="`${userData.company.linkedin_url}`" target="_blank" v-if="userData.company.linkedin_url">
                        <img src="../assets/img/linkedin.png" class="__sociaimgs" />
                      </a>
                      <a :href="`${userData.company.facebook_url}`" target="_blank" v-if="userData.company.facebook_url">
                        <img src="../assets/img/facebook.png" class="__sociaimgs"/>
                      </a>
                    </div>
                  </div>
                  <hr />
                </template>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-7">
              <div class="__movleft">
                <form class="form">
                  <div class="form-group">
                    <label for="" class="form__formlabel">Company’s Name</label>
                    <template v-if="userData.company">
                      <input
                        type="text"
                        class="form-control form__formedit"
                        v-model="userData.company.name"
                      />
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="" class="form__formlabel">Award (s)</label>
                    <template v-if="userData.company">
                      <input
                        type="text"
                        class="form-control form__formedit"
                        v-model="userData.company.awards"
                      />
                    </template>
                  </div>

                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label class="form__formlabel">Country</label>
                        <select
                          class="form-control form__formedit"
                          v-model="selectedCountry"
                          @change="handleChangeCountry"
                        >
                          <option value="" :disabled="countries.length" hidden selected>{{
                            `${usersCountry ? usersCountry : ""}`
                          }}</option>
                          <template v-for="(country, index) of countries">
                            <option
                              :value="country.isoCode"
                              :key="index"
                              :selected="country.name == usersCountry"
                            >
                              {{ country.name }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label for="" class="form__formlabel"
                          >State</label
                        >
                          <select
                            class="form-control form__formedit"
                            v-model="selectedState"
                            @change="handleChangeState"
                          >
                            <option value="" selected hidden :disabled="states.length">{{
                              `${usersState ? usersState : ""}`
                            }}</option>
                            <template v-for="(state, index) of states">
                              <option
                                :value="state.isoCode"
                                :key="index"
                                :selected="index === 0 ? 'selected' : ''"
                              >
                                {{ state.name }}
                              </option>
                            </template>
                          </select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label for="" class="form__formlabel"
                          >Number of employees</label
                        >
                        <template v-if="userData.company">
                          <input
                            type="text"
                            class="form-control form__formedit"
                            placeholder="18,000                                            People"
                            v-model="userData.company.size"
                          />
                        </template>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <template v-if="userData.company">
                      <label for="" class="form__formlabel"
                        >Company’s Description</label>
                        <textarea
                          class="form-control form__formedit"
                          rows="8"
                          v-model="userData.company.about"
                        ></textarea>
                    </template>
                  </div>
                </form>
              <template v-if="userData">
                <p class="profiledetail__bio">Company’s Picture</p>

                <form class="multipleupload">
                  <div class="field">
                    <input
                      type="file"
                      id="files"
                      name="files"
                      class="form-control"
                      @change="onFileChange"
                      multiple
                    />
                  </div>
                </form>
              </template>
                  <div class="form-group">
                    <template v-if="userData.company">
                    <label for="" class="form__formlabel"
                      >Expertises</label
                    >
                      <input
                        type="text"
                        class="form-control form__formedit"
                        v-model="userData.company.expertise"
                      />
                    </template>
                  </div>

                  <div class="form-group">
                    <template v-if="userData.company">
                    <label for="" class="form__formlabel">Company's website</label>
                      <input
                        type="text"
                        class="form-control form__formedit"
                        v-model="userData.company.website_url"
                      />
                    </template>
                  </div>
                  <!-- <template v-if="userData.company"> -->
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                          <label class="form__formlabel">Twitter</label>
                          <input
                            type="text"
                            class="form-control form__formedit"
                            v-model="userData.company.twitter_url"
                          />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                          <label for="" class="form__formlabel">Instagram</label>
                          <input
                            type="text"
                            class="form-control form__formedit"
                            v-model="userData.company.instagram_url"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                          <label class="form__formlabel">LinkedIn</label>
                          <input
                            type="text"
                            class="form-control form__formedit"
                            v-model="userData.company.linkedin_url"
                          />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                          <label for="" class="form__formlabel">Facebook</label>
                          <input
                            type="text"
                            class="form-control form__formedit"
                            v-model="userData.company.facebook_url"
                          />
                        </div>
                      </div>
                    </div>
                  <!-- </template> -->
                </div>

                <div class="row">
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-6">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-6 __shifr">
                    <a
                      href=""
                      class="btn profiledetail__btnsave"
                      @click.prevent="handleUpdateCompanyProfile"
                    >Save <Loader :shouldShow="shouldShowLoader" /> </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="__spacet7p"></div>
          <topFooter />
        </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";

import { mapActions, mapGetters } from "vuex";
import csc from "country-state-city";

import { errorHandler } from "../../utils/helpers";

import topHeader from "../components/topHeader.vue";
import Loader from "../components/loader";
import topFooter from "../components/topFooter";

export default {
  name: "companyEditProfile",
	data() {
		return {
			userData: {},

			usersCountry: null,
      usersState: null,
      usersCity: null,
      countries: [],
      states: [],
      cities: [],
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      userSelectedCountry: "",
      userSelectedState: "",
      existingCountry: "",
      existingCity: "",
      existingState: "",
      selectedCountryName: "",
      selectedStateName: "",
      usersCountry: undefined,
      usersState: undefined,
      companyLogo: undefined,
      shouldShowLoader: false
		}
	},
  components: {
    topHeader,
    Loader,
    topFooter,
  },
	methods: {
		...mapActions([
      'handleUpdateCompany',
      'handleFetchCompany',
      'fetchSingleUser'
    ]),
		handleChangeCountry(event) {
      if (event.target.value) {
        this.states = csc.getStatesOfCountry(`${event.target.value}`);
        this.selectedCountryName = csc.getCountryByCode(
          `${event.target.value}`
        )["name"];
      } else {
        this.states = [];
      }
    },

		handleChangeState(event) {
      if (event.target.value) {
        this.cities = csc.getCitiesOfState(
          `${this.selectedCountry}`,
          `${this.selectedState}`
        );
        let state = this.states.find(
          state => state.isoCode === this.selectedState
        );
        this.selectedStateName = state.name;
      } else {
        this.cities = [];
      }
    },

		async onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.file = files[0];
      this.userData.company.logo = await this.toBase64(files[0]);
    },

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

		async handleUpdateCompanyProfile() {
      try {
        this.shouldShowLoader = true;
        const {
          name,
          awards,
          size,
          logo,
          about,
          expertise,
          instagram_url,
          facebook_url,
          twitter_url,
          linkedin_url,
          website_url
        } = this.userData.company;

        const result = await this.handleUpdateCompany({
          slug: this.userData.company.slug,
          country: this.selectedCountryName || this.usersCountry,
          state: this.selectedStateName || this.usersState,
          name,
          awards,
          size,
          logo,
          about,
          expertise,
          instagram_url,
          facebook_url,
          twitter_url,
          linkedin_url,
          website_url
        });
      
      this.$toast.success(`${result.message}`);

      } catch (error) {
        errorHandler(error, this.$toast);
      } finally{
        this.shouldShowLoader = false;
      }
		}

	}, 
	computed: mapGetters(['user', 'company']),
	async mounted() {

		$(document).ready(function () {
			$(".js-select2").select2({
				closeOnSelect : true,
				placeholder : "Selected expertises",
				templateSelection: iformat,
					templateResult: iformat,
				allowHtml: true,
				allowClear: true,
				width: "100%",
			});
    });

		function iformat(icon, badge,) {
			var originalOption = icon.element;
			var originalOptionBadge = $(originalOption).data('badge');
		 
			return $('<span><i class="fa ' + $(originalOption).data('icon') + '"></i> ' + icon.text + '<span class="badge">' + originalOptionBadge + '</span></span>');
		};

    if (this.user) {
      this.userData = { ...this.user };
      this.usersCountry = this.userData.company.country;
      this.usersState = this.userData.company.state;
      this.companyLogo = this.userData.company.logo;

    }

		this.countries = csc.getAllCountries();

	},
  async created() {
    const { companySlug } = this.$route.params;

    if (!this.user) {
      try {
        await this.handleFetchCompany(companySlug);

        this.userData = {  ...this.company };
        this.usersCountry = this.userData.company.country;
        this.usersState = this.userData.company.state;
        this.companyLogo = this.userData.company.logo;

        this.userData.company.twitter_url = this.userData.company.twitter_url !== 'null' ? this.userData.company.twitter_url:'';
        this.userData.company.linkedin_url = this.userData.company.linkedin_url !== 'null' ? this.userData.company.linkedin_url:'';
        this.userData.company.instagram_url = this.userData.company.instagram_url !== 'null' ? this.userData.company.instagram_url:'';
        this.userData.company.facebook_url = this.userData.company.facebook_url !== 'null' ? this.userData.company.facebook_url:'';
        
      } catch (error) {
        errorHandler(error, this.$toast);
      }
    }
  }
};
</script>

<style>
</style>